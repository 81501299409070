import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import makeAnimated from "react-select/animated";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../constant/base_url";

const UpdateBook = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state.data;
  const animatedComponents = makeAnimated();
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [image, setImage] = useState(null);
  const genreOptions = [
    { value: "BedTime", label: "BedTime" },
    { value: "FairyTale", label: "FairyTale" },
    { value: "Adventure", label: "Adventure" },
    { value: "Fantasy", label: "Fantasy" },
    { value: "Magic", label: "Magic" },
    { value: "ScienceFiction", label: "ScienceFiction" },
    { value: "Other", label: "Other" },
  ];
  const formik = useFormik({
    initialValues: {
      book_title: "",
      description: "",
      genre: [],
      age_group: "",
      book_cover_image: "",
      user_id: "",
      organizationName: "",
      storiesPdf: [],
    },
    validationSchema: Yup.object({
      book_title: Yup.string().required("Title is Required"),
      genre: Yup.array()
        .min(1, "At least one genre is required")
        .required("Genre is Required"),
      age_group: Yup.string().required("Age group is Required"),
      user_id: Yup.string().required("User id is Required"),
      organizationName: Yup.string().required("Organization name is Required"),
      storiesPdf: Yup.array()
        .min(1, "At least one pdf fie is required")
        .required("Pdf is Required"),
    }),
  });

  const extractOriginalFilename = (url) => {
    const pattern = /\/([^\/]+)\?/;
    const match = url.match(pattern);
    if (match) {
      return match[1];
    }
    return null;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    formik.setFieldValue("book_cover_image", file);
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  React.useEffect(() => {
    if (data) {
      formik.setFieldValue("book_title", data.title);
      formik.setFieldValue("user_id", data.user_id);
      formik.setFieldValue("organizationName", data.organization_name);
      formik.setFieldValue(
        "genre",
        data.genre.split(",").map((item) => ({ label: item, value: item }))
      );
      formik.setFieldValue("description", data.description);
      formik.setFieldValue("age_group", data.age_group);
      formik.setFieldValue("storiesPdf", data.pdfs);
      setImage(data.book_cover_image);
    }
  }, [data]);

  const handleSubmit = async () => {
    let payload = new FormData();
    const modifiedFiles = formik.values?.storiesPdf
      .filter((item) => item.file)
      ?.map((item) => {
        const dotIndex = item?.file?.name?.lastIndexOf(".");
        const newFileName =
          item?.file?.name?.substring(0, dotIndex) +
          `_${item.language}` +
          item?.file?.name?.substring(dotIndex);
        return new File([item?.file], newFileName, { type: item?.file?.type });
      });
    payload.append("book_title", formik.values.book_title);
    payload.append(
      "genre",
      formik.values.genre.map((item) => item.label)?.join(", ")
    );
    if (!!formik.values?.removeUrl?.length) {
      formik.values?.removeUrl?.forEach((item) => payload.append("removeUrl", item))
    }
   
    payload.append("age_group", formik.values.age_group);
    if (formik.values?.book_cover_image) {
      payload.append("book_cover_image", formik.values.book_cover_image);
    }
    payload.append("user_id", "12345556");
    payload.append("organizationName", "the educators");
    payload.append("description", formik.values.description);
    modifiedFiles?.forEach((item) => payload.append("storiesPdf", item));
    setLoading(true);
    axios
      .put(`${baseUrl}/s3/book/${data?.id}`, payload)
      .then((res) => {
        setLoading(false);
        navigate("/books");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleViewBook = () => {
    setShowSuccessModal(false);
  };

  const validForSubmit = !formik.values.storiesPdf.some(item => item?.pdfPath) &&  !!(formik.values.storiesPdf.findIndex(item => item?.language && item?.file) === -1)

  return (
    <div className="custom-container" >
      <section className="py-5 content">
        <Container className="px-4 px-lg-5 my-5">
          <Row className="gx-4 gx-lg-5 align-items-start">
            <Col md={6}>
              <div className="position-relative">
                <div
                  className="card-img-top mb-5 mb-md-0"
                  onClick={() => document.getElementById("fileInput").click()}
                  style={{
                    backgroundImage: `url(${
                      image ||
                      "https://dummyimage.com/600x400/dee2e6/6c757d.jpg"
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    paddingTop: "100%", // Aspect ratio 1:1 (width:height)
                    borderRadius: "10px",
                    cursor: "pointer", // Change cursor to pointer
                    border: "5px solid black", // Add black border
                  }}
                />
                {!image && (
                  <div
                    className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center"
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderRadius: "10px",
                      pointerEvents: "none", // Make overlay transparent to clicks
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faUpload}
                      style={{ color: "white", fontSize: "2rem" }}
                    />
                    <h5 className="text-white mt-3">Select Book Cover</h5>
                  </div>
                )}
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
              </div>
              {/* {errors.image && <div className="text-danger">{errors.image}</div>} */}
            </Col>
            <Col md={6}>
              <h1 className="display-6 fw-bolder">Update Book Details</h1>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Title <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter title"
                    id="book_title"
                    name="book_title"
                    value={formik.values.book_title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      !!formik.touched.book_title && !!formik.errors.book_title
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.book_title}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Genre <span className="text-danger">*</span>
                  </Form.Label>
                  <InputGroup>
                    <Select
                      isMulti={true}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      id="genre"
                      name="genre"
                      classNamePrefix="select"
                      options={genreOptions}
                      className={
                        !!formik.touched.genre &&
                        !!formik.errors.genre &&
                        "is-invalid"
                      }
                      value={formik.values?.genre || []}
                      onChange={(data) => formik.setFieldValue("genre", data)}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        !!formik.touched.genre && !!formik.errors.genre
                      }
                    />
                  </InputGroup>
                  {!!formik.touched.genre && !!formik.errors.genre && (
                    <div className="text-danger">{formik.errors.genre}</div>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Age Group <span className="text-danger">*</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="select"
                      name="age_group"
                      id="age_group"
                      value={formik.values.age_group}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        !!formik.touched.age_group && !!formik.errors.age_group
                      }
                    >
                      <option value="">Select age group</option>
                      <option value="Less than 6 years">
                        Less than 6 years
                      </option>
                      <option value="6-8 years">6-8 years</option>
                      <option value="8-10 years">8-10 years</option>
                      <option value="10-12 years">10-12 years</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.age_group}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>
                    Upload PDFs <span className="text-danger">*</span>
                  </Form.Label>
                  {formik.values?.storiesPdf?.map((pdf, index) => (
                    <div key={index} className="mb-4 d-flex align-items-center">
                      <Form.Group className="flex-grow-1 me-2">
                        <Form.Control
                          as="select"
                          value={
                            formik.values.storiesPdf?.[
                              index
                            ]?.language?.includes("_")
                              ? formik.values.storiesPdf?.[
                                  index
                                ]?.language?.split("_")?.[1]
                              : formik.values.storiesPdf[index]?.language
                          }
                          onChange={(e) => {
                            if (
                              formik.values?.storiesPdf?.findIndex(
                                (item) => item?.language?.toLowerCase() === e.target.value.toLowerCase()
                              ) !== -1
                            ) {
                              alert("Pdf of this Language is already added");
                              return;
                            }
                            formik.setFieldValue(`storiesPdf[${index}]`, {
                              ...formik.values.storiesPdf[index],
                              language: e.target.value,
                            });
                          }}
                          // isInvalid={!!errors.pdfFiles}
                        >
                          <option value="">Select Language</option>
                          <option value="English">English</option>
                          <option value="Chinese">Chinese</option>
                          <option value="Hindi">Hindi</option>
                          <option value="Urdu">Urdu</option>
                          <option value="German">German</option>
                          <option value="French">French</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group
                        className={
                          formik.values?.storiesPdf?.[index]?.pdfPath?.includes(
                            "https://fiction-genie"
                          )
                            ? "input-upload me-2"
                            : "position-relative me-2"
                        }
                      >
                        {formik.values?.storiesPdf?.[index]?.pdfPath?.includes(
                          "https://fiction-genie"
                        ) ? (
                          <span>
                            {extractOriginalFilename(
                              formik.values.storiesPdf[index].pdfPath
                            )}
                          </span>
                        ) : (
                          //   <Form.Control>
                          //   type="text"
                          //   value=
                          //   {extractOriginalFilename(
                          //     formik.values.storiesPdf[index].pdfPath
                          //   )}
                          // </Form.Control>
                          <Form.Control
                            type="file"
                            accept=".pdf"
                            onChange={(e) => {
                              if (
                                formik.values.storiesPdf?.filter(
                                  (item) =>
                                    item?.language?.toLowerCase() ===
                                    formik.values?.storiesPdf?.[index]?.language?.toLowerCase()
                                )?.length > 1
                              ) {
                                alert("Pdf of this Language is already added");
                                return;
                              }
                              formik.setFieldValue(`storiesPdf[${index}]`, {
                                ...formik.values.storiesPdf[index],
                                file: e.target.files[0],
                              });
                            }}
                            // isInvalid={!!errors.pdfFiles}
                          />
                        )}
                      </Form.Group>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-remove-${index}`}>
                            Remove
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="danger"
                          className="ms-1"
                          onClick={() => {
                            if (formik.values?.storiesPdf.length === 1){
                              alert('Atleast 1 pdf should be there. ')
                              return
                            }
                            if (formik.values?.storiesPdf?.[index]?.book_id){
                              const fileName = new URL(formik.values?.storiesPdf?.[index]?.pdfPath).pathname.replaceAll("%20", " ") || ''
                              const temp = [...(formik.values?.removeUrl || [])]?.concat(fileName)
                              formik.setFieldValue("removeUrl", temp);
                            }
                            const temp = [...formik.values.storiesPdf];
                            temp.splice(index, 1);
                            formik.setFieldValue("storiesPdf", temp);
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-add`}>Add More</Tooltip>}
                      >
                        <Button
                          variant="primary"
                          className="ms-1"
                          onClick={() =>
                            formik.setFieldValue("storiesPdf", [
                              ...formik.values.storiesPdf,
                              { language: "", file: null },
                            ])
                          }
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  ))}
                  {!!formik.touched.storiesPdf &&
                    !!formik.errors.storiesPdf && (
                      <div className="text-danger">
                        {formik.errors.storiesPdf}
                      </div>
                    )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Book Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter book description (Optional)"
                    name="description"
                    id="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Group>
                <Button
                  variant="success"
                  onClick={handleSubmit}
                  className="mt-3 btn-width"
                  disabled={ validForSubmit ||  loading}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Upload my story :)"
                  )}
                </Button>
              </Form>
              <Button
                variant="secondary"
                className="mt-3"
                onClick={() => setShowSuccessModal(true)}
              >
                Show Success Modal (Test)
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Book Added Successfully :)</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleViewBook}>
            View Book
          </Button>
          <Button variant="secondary" onClick={() => {}}>
            Add Another
          </Button>
        </Modal.Footer>
      </Modal>
      <footer className="py-2 bg-dark custom-footer">
        <Container>
          <p className="m-0 text-center text-white">
            Copyright &copy; FictionGenie 2024
          </p>
        </Container>
      </footer>
    </div>
  );
};

export default UpdateBook;
