import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { baseUrl } from '../../constant/base_url.js'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx'
import SignupModal from '../SignupModal/SignupModal.jsx'
import axios from 'axios'
import LoginModal from '../LoginModal/LoginModal.jsx'
import ForgotModal from '../ForgotModal/ForgotModal.jsx'
import OtpModal from '../OtpModal/OtpModal.jsx'
import ResetPasswordModal from '../ResetPasswordModal/ResetPasswordModal.jsx'
import AddProfileModal from '../AddProfileModal/AddProfileModal.jsx'
import './Stories.css'

// BookRow Component
const BookRow = ({ genre, books, setShowSignupPopup, isLoggedIn }) => {
  const navigate = useNavigate()
  return (
    <div className="book-row-container">
      <h3 className="genre-title">{genre}</h3>
      <div className="book-row d-flex flex-nowrap overflow-auto">
        {books.map((book, index) => (
          <div
            className="book-item d-flex flex-column rounded bg-dark"
            onClick={() =>
              !isLoggedIn
                ? setShowSignupPopup(true)
                : navigate(`/book-detail/${book?.title}`, {
                    state: { data: book },
                  })
            }
          >
            <img
              src={book?.book_cover_image}
              alt={book?.title}
              style={{
                width: '200px',
                height: '300px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

const Stories = ({ isLoggedIn, isheaderNotFixed, setIsLoggenIn }) => {
  const [allBooks, setAllBooks] = useState([])
  const [selectedData, setSelectedData] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showLoginPopup, setShowLoginPopup] = useState(false)
  const [showSignupPopup, setShowSignupPopup] = useState(false)
  const [showForgotPopup, setShowForgotPopup] = useState(false)
  const [showOtpPopup, setShowOtpPopup] = useState(false)
  const [showResetpopup, setShowResetpopup] = useState(false)
  const [showAddProfileModal, setShowAddProfileModal] = useState(false)
  const [authInfo, setAuthInfo] = useState({email: '', otp: ''})
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const redirected = queryParams.get('redirected');

  console.log('setIsLoggenIn --> ', setIsLoggenIn)

  useEffect(()=> {
    if (redirected){
      // setIsLoggenIn(true)
      // setShowAddProfileModal(true)
      setShowLoginPopup(true)
    }
  },[redirected, setIsLoggenIn])

  useEffect(() => {
    getAllBooks()
  }, [])

  const getAllBooks = async () => {
    try {
      const response = await axios.get(`${baseUrl}/s3/book/fg`)
      setAllBooks(response.data)
    } catch (error) {
      console.error('Error fetching books:', error)
      setAllBooks([])
    }
  }

  const deleteBook = async () => {
    try {
      await axios.delete(`${baseUrl}/s3/book-inactive/${selectedData?.id}`)
      setShowDeleteModal(false)
      getAllBooks()
    } catch (error) {
      console.error('Error deleting book:', error)
    }
  }

  const genres = Array.from(new Set(allBooks?.data?.map((book) => book.genre)))

  const getBooksByGenre = (genre) => {
    const filteredBooks =
      allBooks?.data?.filter((book) => book.genre === genre) || []
    return Array.from({ length: 25 }, () => filteredBooks).flat()
  }

  const closeAll = () => {
    setShowLoginPopup(false)
    setShowSignupPopup(false)
    setShowForgotPopup(false)
    setShowOtpPopup(false)
    setShowResetpopup(false)
    setShowAddProfileModal(false)
  }

  return (
    <div className="full-screen-container custom-container">
      {!isLoggedIn && (
        <header className={`header`}>
          <h2 className="fw-bolder">FictionGenie</h2>
        </header>
      )}

      <main className="content-stories content">
        {!allBooks?.data?.length && (
          <div className="d-flex justify-content-center align-items-center">
            <p className="text-center text-white">No Data Found</p>
          </div>
        )}
        <Container fluid className="mt-5">
          {genres.map((genre, index) => (
            <BookRow
              key={index}
              genre={genre}
              books={getBooksByGenre(genre)}
              setShowSignupPopup={setShowSignupPopup}
              isLoggedIn={isLoggedIn}
            />
          ))}
        </Container>
      </main>
      <footer className="py-2 bg-dark custom-footer">
        <Container>
          <p className="m-0 text-center text-white">
            Copyright &copy; FictionGenie 2024
          </p>
        </Container>
      </footer>
      {showDeleteModal && (
        <ConfirmationModal
          title={'Confirmation Modal'}
          message={'Are you sure you want to delete this book?'}
          show={showDeleteModal}
          onremove={deleteBook}
          onHide={() => setShowDeleteModal(false)}
        />
      )}
      {showLoginPopup && (
        <LoginModal
          show={showLoginPopup}
          onHide={setShowLoginPopup}
          setShowForgotPopup={setShowForgotPopup}
          setShowSignupPopup={setShowSignupPopup}
          closeAll= {closeAll}
          setIsLoggenIn={setIsLoggenIn}
          setShowAddProfileModal={setShowAddProfileModal}
        />
      )}
      {showSignupPopup && (
        <SignupModal
          show={showSignupPopup}
          onHide={setShowSignupPopup}
          setShowLoginPopup={setShowLoginPopup}
          closeAll= {closeAll}
        />
      )}
      {showForgotPopup && (
        <ForgotModal
          show={showForgotPopup}
          onHide={setShowSignupPopup}
          setShowLoginPopup={setShowLoginPopup}
          setShowOtpPopup={setShowOtpPopup}
          closeAll= {closeAll}
          setAuthInfo={setAuthInfo}
          authInfo={authInfo}
        />
      )}
      {showOtpPopup && (
        <OtpModal
          show={showOtpPopup}
          onHide={setShowOtpPopup}
          setShowLoginPopup={setShowLoginPopup}
          setShowResetpopup={setShowResetpopup}
          setAuthInfo={setAuthInfo}
          authInfo={authInfo}
          closeAll= {closeAll}
        />
      )}
      {showResetpopup && (
        <ResetPasswordModal
          show={showResetpopup}
          onHide={setShowResetpopup}
          setShowLoginPopup={setShowLoginPopup}
          closeAll= {closeAll}
          setAuthInfo={setAuthInfo}
          authInfo={authInfo}
        />
      )}
      {showAddProfileModal && (
        <AddProfileModal
          show={showAddProfileModal}
          onHide={setShowAddProfileModal}
          setShowLoginPopup={setShowLoginPopup}
          closeAll= {closeAll}
          setAuthInfo={setAuthInfo}
          authInfo={authInfo}
          setIsLoggenIn={setIsLoggenIn}
        />
      )}
    </div>
  )
}

export default Stories
