import React from "react";
import PdfExtractor from "../PdfExtract/pdf-update-extraction";
import { useLocation } from "react-router-dom";

const PdfMain = () => {
  const location = useLocation();
  const data = location?.state?.data;
  return (
    <div>
      <PdfExtractor data={data} />
    </div>
  );
};

export default PdfMain;
