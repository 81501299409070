import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Button, Spinner } from 'react-bootstrap'
import { faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import { baseUrl } from '../../constant/base_url.js'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.jsx'
import axios from 'axios'

// BookListItem Component
export const BookListItem = ({
  book,
  deleteBook,
  setSelectedData,
  setShowDeleteModal,
  isLoggedIn,
}) => {
  const navigate = useNavigate()
  return (
    <div className="d-flex flex-column p-3 mb-3 rounded bg-light">
      <div className='content'>
      <div className="d-flex align-items-center mb-3">
        <div
          onClick={() =>
            navigate(`/book-detail/${book?.title}`, { state: { data: book } })
          }
        >
          <img
            src={book?.book_cover_image}
            alt={book?.title}
            style={{
              width: '100px',
              height: '120px',
              marginRight: '15px',
              borderRadius: '5px',
            }}
          />
        </div>
        <div>
          <small className="text-muted">{book?.id}</small>
          <h5 className="mb-1">{book?.title}</h5>
          <small className="text-muted">
            {book?.genre} | {book?.organization_name}
          </small>
        </div>
      </div>
      <div className="d-flex">
        {isLoggedIn && (
          <>
            <span className="me-2">
              <Button
                variant="outline-dark"
                onClick={() =>
                  navigate(`/update-book/${book?.title}`, {
                    state: { data: book },
                  })
                }
              >
                <FontAwesomeIcon icon={faEdit} /> update
              </Button>
            </span>
            <span className="me-2">
              <Button
                variant="outline-danger"
                onClick={() => {
                  setSelectedData(book)
                  setShowDeleteModal(true)
                }}
              >
                <FontAwesomeIcon icon={faTrash} /> Delete
              </Button>
            </span>
          </>
        )}

        <span className="me-2">
          <Button
            variant="outline-dark"
            onClick={() =>
              navigate(`/book-detail/${book?.title}`, { state: { data: book } })
            }
          >
            View Book
          </Button>
        </span>
      </div>

      </div>
    </div>
  )
}

const Books = ({ isLoggedIn }) => {
  const [allBooks, setAllBooks] = React.useState([])
  const [selectedData, setSelectedData] = React.useState({})
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    setIsLoading(true)
    getAllBook()
  }, [])

  const getAllBook = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/s3/book/fg`)
      setAllBooks(data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }
  const deleteBook = async () => {
    await axios.delete(`${baseUrl}/s3/book-inactive/${selectedData?.id}`)
    setShowDeleteModal(false)
    getAllBook()
  }

  return (
    <div className='custom-container'>
      <section className="py-5 content">
        <Container className="px-4 px-lg-5 mt-5">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2 className="fw-bolder">Book List</h2>
            {isLoggedIn && (
              <Link to="/add-book">
                <Button variant="primary">
                  <FontAwesomeIcon icon={faPlus} /> Add Book
                </Button>
              </Link>
            )}
          </div>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '400px' }}
            >
              <Spinner animation="border" className="spinner" />
              <p className="ml-11px mt-4">Loading</p>
            </div>
          ) : !allBooks?.data?.length ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '200px' }}
            >
              <p className="text-center">No Data Found</p>
            </div>
          ) : (
            allBooks?.data?.map((book) => (
              <BookListItem
                key={book?.id}
                book={book}
                deleteBook={deleteBook}
                setSelectedData={setSelectedData}
                setShowDeleteModal={setShowDeleteModal}
                isLoggedIn={isLoggedIn}
              />
            ))
          )}
        </Container>
      </section>
      <footer className="py-2 bg-dark custom-footer">
        <Container>
          <p className="m-0 text-center text-white">
            Copyright &copy; FictionGenie 2024
          </p>
        </Container>
      </footer>
      {showDeleteModal && (
        <ConfirmationModal
          title={'Confirmation Modal'}
          message={'Are you sure you want to delete this book ?'}
          show={showDeleteModal}
          onremove={deleteBook}
          onHide={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  )
}

export default Books
