import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import close from '../../assets/svg/close.svg'
import './style.css'

const OtpModal = ({show, onHide,  setShowResetpopup, setIsLoggenIn,setShowLoginPopup, closeAll, setAuthInfo, authInfo }) => {
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
        otp: Yup.string().required("Required"),
    }),
  });

  const create = async () => {
    setAuthInfo({...authInfo, otp: formik.values.otp })
    closeAll()
    setShowResetpopup(true)
  };


  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      className="login-modal"
      centered
    >
      <span onClick={closeAll}>
        <img src={close} alt="" className="close-icon"/>
      </span>
      <Modal.Body className="d-flex flex-column align-items-center ">
      <div className="w-100 header-custom">
          <p>OTP</p>
        </div>
        <Form.Group className="mb-3 input-field">
          <Form.Label>Please enter OTP code</Form.Label>
          <Form.Control
            type="text"
            placeholder="OTP"
            id="otp"
            name="otp"
            value={formik.values.otp}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={
              !!formik.touched.otp && !!formik.errors.otp
            }
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.otp}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="login-navigate-ref">
          <p>Already have an account? <span className="prominent-text" onClick={()=> {onHide() ; setShowLoginPopup(true)}}>Login here!</span></p>
        </div>
        <Button className="auth-action-btn" onClick={create} variant="primary">
          Submit
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default OtpModal;
