import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBookReader,
  faClock,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import './BookDetails.css'
import axios from 'axios'
import ShowLanguageModal from '../showLanguageModal/showLanguageModal'
import { baseUrl } from '../../constant/base_url'
import './BookDetails.css'

const BookDetails = ({ isLoggedIn, user }) => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  console.log('params --> ', id)
  const navigate = useNavigate()
  const location = useLocation()
  const data = location.state?.data

  const [showLanguageModal, setShowLanguageModal] = useState(false)
  const [bookData, setBookData] = useState({})
  const [lastReadDate, setLastReadDate] = useState('')

  const allanguages = (data || bookData)?.pdfs?.map((item) => item?.language)

  useEffect(() => {
    if (!data) {
      async function getBook() {
        axios
          .get(`${baseUrl}/s3/get-book/${id}`)
          .then(({ data }) => {
            setBookData(data?.data || {})
          })
          .catch((err) => {
            console.log(err)
          })
      }
      getBook()
    }
  }, [data])

  useEffect(() => {
    // Get the current date and format it
    const today = new Date()
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    const formattedDate = today.toLocaleDateString(undefined, options)
    setLastReadDate(formattedDate)
  }, [])

  const checkToShow = () => {
    if (data?.pdfs?.length > 1) {
      setShowLanguageModal(true)
      return
    }

    navigate(`/${(data || bookData)?.title}`, {
      state: { data: { ...(data || bookData)?.pdfs?.[0], title: (data || bookData)?.title } },
    })
  }
  return (
    <div className='custom-container'>
      {
        !isLoggedIn  && (
          <header className={`header`}>
          <h2 className="fw-bolder">FictionGenie</h2>
        </header>
        )
      }
      <section className="py-5 content">
        <Container className="px-4 px-lg-5 my-5">
          <Row className="gx-4 gx-lg-5 align-items-center">
            <Col md={6}>
              <img
                className="card-img-top mb-5 mb-md-0"
                src={(data || bookData)?.book_cover_image}
                alt="..."
                style={{ borderRadius: '10px' }}
              />
            </Col>
            <Col md={6}>
              {/* <div className="small mb-1">{`Book-Id: ${
                (data || bookData)?.id || ''
              }`}</div> */}
              <h1 className="display-5 fw-bolder">
                {(data || bookData)?.title || ''}
              </h1>
              <div className="fs-5 mb-5">
                <span>
                  {' '}
                  <b>Genre: </b> {(data || bookData)?.genre || ''}
                </span>
                <br />
                <span>
                  {' '}
                  <b>Age: </b> {(data || bookData)?.age_group || ''}
                </span>
                <br />
                <span>
                  {' '}
                  <b>Reading Time: </b> 15 Minutes
                </span>
                <br />
                <span>
                  {' '}
                  <b>Pages: </b> 15
                </span>
                <br />
                <span>
                  {' '}
                  <b>Language: </b>
                  {allanguages?.join(', ') || ''}
                </span>
                <br />
              </div>

              <p className="lead">{(data || bookData)?.description || ''}</p>

              {/* New stats section */}
              <div className="d-flex mb-3 flex-wrap">
                {/* <div className="me-3 d-flex align-items-center mb-2">
                  <FontAwesomeIcon
                    icon={faBookReader}
                    style={{ marginRight: '5px' }}
                  />
                  <span>
                    <b>Read:</b> 120 times
                  </span>
                </div> */}
                {/* <div className="me-3 d-flex align-items-center mb-2">
                  <FontAwesomeIcon
                    icon={faClock}
                    style={{ marginRight: '5px' }}
                  />
                  <span id="last-read">
                    <b>Last Read:</b> {lastReadDate}
                  </span>
                </div> */}
                <div className="d-flex align-items-center mb-2">
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ marginRight: '5px' }}
                  />
                  <span>
                    <b>Uploaded By:</b>{' '}
                    {(data || bookData)?.organization_name || ''}
                  </span>
                </div>
              </div>
              <div className="d-flex">
                {isLoggedIn && user?.role === 'admin' && (
                  <Button
                    onClick={() =>
                      navigate(`/update-book/${(data || bookData)?.id}`, {
                        state: { data: data || bookData },
                      })
                    }
                    className="flex-shrink-0"
                    type="button"
                    style={{
                      color: 'white',
                      borderColor: 'black',
                      background: 'black',
                    }}
                  >
                    <i className="bi-cart-fill me-1"></i>
                    Edit Book
                  </Button>
                )}

                <Button
                  onClick={() => checkToShow()}
                  // onClick={() =>
                  //   navigate(`/pdf-extract`, { state: { data } })
                  // }
                  className="flex-shrink-0 mx-4"
                  type="button"
                  style={{
                    color: 'white',
                    borderColor: 'black',
                    background: 'black',
                  }}
                >
                  <i className="bi-cart-fill me-1"></i>
                  Read Story
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="py-5 bg-light">
        <Container className="px-4 px-lg-5 mt-5">
          <h2 className="fw-bolder mb-4">Other Books</h2>
          <Row className="gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">
            <Col className="mb-5">
              <Card className="h-100">
                <Card.Img
                  variant="top"
                  src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
                  alt="..."
                />
                <Card.Body className="p-4">
                  <div className="text-center">
                    <h5 className="fw-bolder">Fancy Product</h5>
                    <div> Adventure</div>
                    <div> 5-8 Years Old</div>
                  </div>
                </Card.Body>
                <Card.Footer className="p-4 pt-0 border-top-0 bg-transparent">
                  <div className="text-center">
                    <Button className=" mt-auto" href="#">
                      View Book
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col className="mb-5">
              <Card className="h-100">
                <Card.Img
                  variant="top"
                  src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
                  alt="..."
                />
                <Card.Body className="p-4">
                  <div className="text-center">
                    <h5 className="fw-bolder">Fancy Product</h5>
                    <div> Adventure</div>
                    <div> 5-8 Years Old</div>
                  </div>
                </Card.Body>
                <Card.Footer className="p-4 pt-0 border-top-0 bg-transparent">
                  <div className="text-center">
                    <Button className=" mt-auto" href="#">
                      View Book
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col className="mb-5">
              <Card className="h-100">
                <Card.Img
                  variant="top"
                  src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
                  alt="..."
                />
                <Card.Body className="p-4">
                  <div className="text-center">
                    <h5 className="fw-bolder">Fancy Product</h5>
                    <div> Adventure</div>
                    <div> 5-8 Years Old</div>
                  </div>
                </Card.Body>
                <Card.Footer className="p-4 pt-0 border-top-0 bg-transparent">
                  <div className="text-center">
                    <Button className=" mt-auto" href="#">
                      View Book
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col className="mb-5">
              <Card className="h-100">
                <Card.Img
                  variant="top"
                  src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
                  alt="..."
                />
                <Card.Body className="p-4">
                  <div className="text-center">
                    <h5 className="fw-bolder">Fancy Product</h5>
                    <div> Adventure</div>
                    <div> 5-8 Years Old</div>
                  </div>
                </Card.Body>
                <Card.Footer className="p-4 pt-0 border-top-0 bg-transparent">
                  <div className="text-center">
                    <Button className=" mt-auto" href="#">
                      View Book
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <Col className="mb-5">
              <Card className="h-100">
                <Card.Img
                  variant="top"
                  src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
                  alt="..."
                />
                <Card.Body className="p-4">
                  <div className="text-center">
                    <h5 className="fw-bolder">Fancy Product</h5>
                    <div> Adventure</div>
                    <div> 5-8 Years Old</div>
                  </div>
                </Card.Body>
                <Card.Footer className="p-4 pt-0 border-top-0 bg-transparent">
                  <div className="text-center">
                    <Button className=" mt-auto" href="#">
                      View Book
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </Col>{' '}
            <Col className="mb-5">
              <Card className="h-100">
                <Card.Img
                  variant="top"
                  src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
                  alt="..."
                />
                <Card.Body className="p-4">
                  <div className="text-center">
                    <h5 className="fw-bolder">Fancy Product</h5>
                    <div> Adventure</div>
                    <div> 5-8 Years Old</div>
                  </div>
                </Card.Body>
                <Card.Footer className="p-4 pt-0 border-top-0 bg-transparent">
                  <div className="text-center">
                    <Button className=" mt-auto" href="#">
                      View Book
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </section> */}
      <footer className="py-2 bg-dark custom-footer">
        <Container>
          <p className="m-0 text-center text-white">
            Copyright &copy; FictionGenie 2024
          </p>
        </Container>
      </footer>
      {showLanguageModal && (
        <ShowLanguageModal
          showModal={showLanguageModal}
          onHide={() => setShowLanguageModal(false)}
          data={data}
        />
      )}
    </div>
  )
}
export default BookDetails
