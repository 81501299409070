import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faCloudDownloadAlt, faFileAlt, faBookReader, faClock, faUser } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css'; // Assuming you save the CSS file as Home.css

const HomePage = () => {
    return (
        <div className='custom-container'>
            <header className="py-5 position-relative bg-light rounded-3 content">
                <Container>
                    <Row className="align-items-center">
                        <Col md={8}>
                            <div className="p-4 p-lg-5">
                                <h1 className="display-5 fw-bold text-left">A warm welcome!</h1>
                                <p className="fs-6 text-left">
                                    Bootstrap utility classes are used to create this jumbotron since the old component has been removed from the framework. Why create custom CSS when you can use utilities?
                                </p>
                                <div className="d-flex mb-3 flex-wrap">
                                    <div className="me-3 d-flex align-items-center mb-2">
                                        <FontAwesomeIcon icon={faBookReader} style={{ marginRight: '5px' }} />
                                        <span><b>Books:</b> 120 </span>
                                    </div>
                                    <div className="me-3 d-flex align-items-center mb-2">
                                        <FontAwesomeIcon icon={faClock} style={{ marginRight: '5px' }} />
                                        <span><b>Last Login:</b> May 30, 2024</span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <FontAwesomeIcon icon={faUser} style={{ marginRight: '5px' }} />
                                        <span><b>User:</b> FictionGenie</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} className="position-relative">
                            <img src="https://test.fictiongenie.com/static/media/Genie.33889fd41406a3cff6cc.png" alt="Placeholder" className="img-fluid rounded-3 jumbotron-img hover" />
                        </Col>
                    </Row>
                </Container>
            </header>
            {/* Page Content */}
            <section className="pt-4">
                <Container>
                    {/* Page Features */}
                    <Row className="gx-lg-5">
                        <Col lg={4} xxl={4} className="mb-5">
                            <Card className="bg-light border-0 h-100 text-center">
                            <div className="feature-icon" style={{ padding: '10px' }}>
                                    <FontAwesomeIcon icon={faFolder} size="2x" />
                                </div>
                                <Card.Body className="p-4 p-lg-5 pt-0 pt-lg-0">
                                    <h2 className="fs-4 fw-bold">Fresh new layout</h2>
                                    <p className="mb-0">With Bootstrap 5, we've created a fresh new layout for this template!</p>
                                    <br></br>
                                    <Button variant="primary" size="md">View Books</Button>
                             
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} xxl={4} className="mb-5">
                            <Card className="bg-light border-0 h-100 text-center">
                                <div className="feature-icon" style={{ padding: '10px' }}>
                                    <FontAwesomeIcon icon={faCloudDownloadAlt} size="2x" />
                                </div>
                                <Card.Body className="p-4 p-lg-5 pt-0 pt-lg-0">
                                    <h2 className="fs-4 fw-bold">Free to download</h2>
                                    <p className="mb-0">As always, Start Bootstrap has a powerful collection of free templates.</p>
                                    <br></br>
                                    <Button variant="primary" size="md">Add Books</Button>
                             
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} xxl={4} className="mb-5">
                            <Card className="bg-light border-0 h-100 text-center">
                                <div className="feature-icon" style={{ padding: '10px' }}>
                                    <FontAwesomeIcon icon={faFileAlt} size="2x" />
                                </div>
                                <Card.Body className="p-4 p-lg-5 pt-0 pt-lg-0">
                                    <h2 className="fs-4 fw-bold">Jumbotron hero header</h2>
                                    <p className="mb-0">The heroic part of this template is the jumbotron hero header!</p>
                                    <br></br>
                                    <Button variant="primary" size="md">View Stats</Button>
                             
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} xxl={4} className="mb-5">
                            <Card className="bg-light border-0 h-100 text-center">
                                <div className="feature-icon" style={{ padding: '10px' }}>
                                    <FontAwesomeIcon icon={faFileAlt} size="2x" />
                                </div>
                                <Card.Body className="p-4 p-lg-5 pt-0 pt-lg-0">
                                    <h2 className="fs-4 fw-bold">Jumbotron hero header</h2>
                                    <p className="mb-0">The heroic part of this template is the jumbotron hero header!</p>
                                    <br></br>
                                    <Button variant="primary" size="md">View Accounts</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <footer className="py-2 bg-dark custom-footer">
                <Container>
                    <p className="m-0 text-center text-white">Copyright &copy; FictionGenie 2024</p>
                </Container>
            </footer>
        </div>
    );
};

export default HomePage;
