import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { baseUrl } from "../../constant/base_url";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "../../config";
import Spinner from "react-bootstrap/Spinner";
import close from '../../assets/svg/close.svg'
import config from '../../Helpers/config.json'
import './style.css'

const ForgotModal = ({ show, onHide, setShowSignupPopup, setIsLoggenIn, setShowLoginPopup, closeAll, setShowOtpPopup,authInfo, setAuthInfo }) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid Email address")
        .required("Email is Required"),
    }),
  });

  const forgot = () => {
    axios.post(`${config['baseUrl2']}auth/forgotPassword`,
      {
        email: formik.values.email,
      })
      .then((res) => {
        if (res.data.success === true) {
          setAuthInfo({...authInfo, email: formik.values.email})
          setLoading(false)
          closeAll()
          setShowOtpPopup(true)
          // navigate('/otp', { state: { email } });
        }
      })
      .catch((err)=> {
        console.log('err ---> ', err)
      })
  };


  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      className="login-modal"
      centered
    >
      <span onClick={closeAll}>
        <img src={close} alt="" className="close-icon" />
      </span>
      <Modal.Body className="d-flex flex-column align-items-center ">
        <div className="w-100 header-custom">
          <p>Forgot password</p>
        </div>
        <Form.Group className="mb-3 input-field">
          <Form.Label>Please Enter your Email address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.touched.email && !!formik.errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="login-navigate-ref">
          <p>Already have an account? <span className="prominent-text" onClick={() => { onHide(); setShowLoginPopup(true) }}>Login here!</span></p>
        </div>
        <Button className="auth-action-btn" onClick={forgot} variant="primary">
          {loading ? <Spinner animation="border" size={5} /> : "Send"}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotModal;
