import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";
import close from "../../assets/svg/close.svg";
import config from '../../Helpers/config.json'
import "./style.css";

const ResetPasswordModal = ({
  show,
  onHide,
  setShowLoginPopup,
  onCreate,
  closeAll,
  setAuthInfo,
  authInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
  });

  const reset = () => {
    setLoading(true);
    fetch(`${config["baseUrl2"]}auth/resetPassword`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        email: authInfo.email,
        token: authInfo.otp,
        newPassword: formik.values.newPassword,
      }),
    })
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((res) => {
        setLoading(false);
        if (res.message === "Password reset successfully") {
          console.log(res.message, "<< success");
          closeAll();
          setShowLoginPopup(true)
          // navigate("/");
        } else {
          setLoading(false);
          alert(res.message, "<< unsuccessful");
        }
      });
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      className="login-modal"
      centered
    >
      <span onClick={closeAll}>
        <img src={close} alt="" className="close-icon" />
      </span>
      <Modal.Body className="d-flex flex-column align-items-center ">
        <div className="w-100 header-custom">
          <p>Reset Password</p>
        </div>

        <Form.Group className="mb-3 input-field">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            id="newPassword"
            name="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.touched.newPassword && !!formik.errors.newPassword}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.newPassword}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 input-field">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            id="confirmPassword"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.touched.confirmPassword && !!formik.errors.confirmPassword}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.confirmPassword}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="login-navigate-ref">
          <p>
            Already have an account?{" "}
            <span
              className="prominent-text"
              onClick={() => {
                onHide();
                setShowLoginPopup(true);
              }}
            >
              Login here!
            </span>
          </p>
        </div>
        <Button className="auth-action-btn" onClick={reset} variant="primary">
          {loading ? <Spinner animation="border" size={5} /> : "Reset"}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPasswordModal;
