import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import { auth } from "../../config";
import Spinner from "react-bootstrap/Spinner";
import close from '../../assets/svg/close.svg'
import { signInWithEmailAndPassword } from "firebase/auth";
import config from '../../Helpers/config.json'
import './style.css'
import { useNavigate } from "react-router-dom";
import { credential } from "../../constant/credential";

const LoginModal = ({show, onHide, setIsLoggenIn, setShowForgotPopup, setShowSignupPopup, closeAll, setShowAddProfileModal}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid Email address")
        .required("Email is Required"),
      password: Yup.string().required("Password Required"),
    }),
  });

  const openForgotModal = () => {
    onHide()
    setShowSignupPopup(false)
    setShowForgotPopup(true)
  }

  const openSignupModal = () => {
    onHide()
    setShowForgotPopup(false)
    setShowSignupPopup(true)
  }
  
  const login = () => {
    if (formik.values.email === credential.email && formik.values.password === credential.password) {
      localStorage.setItem('token', formik.values.email)
      localStorage.setItem('uid', formik.values.email)
      localStorage.setItem('role', 'admin')
      setLoading(false)
      closeAll()
      setIsLoggenIn(true)
      navigate('/')
      return
    }
    signInWithEmailAndPassword(auth, formik.values.email, formik.values.password)
      .then((userCredential) => {
        const user = userCredential.user;
        setLoading(true)
        user.getIdToken().then(function (idToken) {
          fetch(`${config['localUrl']}api/auth/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ idToken: idToken })
          })
            .then(response => response.json())
            .then(data => {
              if (data.success) {
                localStorage.setItem('email', formik.values.email);
                localStorage.setItem('data', JSON.stringify(data));
                localStorage.setItem('token', data?.token);
                localStorage.setItem('role', 'user')
                fetch(`${config['localUrl']}api/profile/listProfiles?userId=${data?.uid}`, {
                  method: 'GET',
                  headers: { 'content-type': 'application/json' },
                })
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.success === true) {
                      if (res?.profiles.length > 0) {
                        setLoading(false)
                        // onHide()
                        closeAll()
                        setIsLoggenIn(true)
                        navigate('/')
                      } else {
                        setLoading(false)
                        setIsLoggenIn(true)
                        closeAll()
                        setShowAddProfileModal(true)
                      }
                    } else {
                      setLoading(false)
                    }
                  });
              } else {
                setLoading(false)
                if (data.message === "Email not verified") {
                  alert('email not verifed')
                }
                console.error('Login Failed:', data.message);
              }
            })
            .catch((error) => {
              setLoading(false)
              console.error('Error:', error);
            });
        });
      })
      .catch((error) => {
        setLoading(false)
        console.error('Firebase Authentication Error:', error);
      });
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      className="login-modal"
      centered
    >
      <span onClick={closeAll}>
        <img src={close} alt="" className="close-icon"/>
      </span>
      <Modal.Body className="d-flex flex-column align-items-center ">
        <div className="w-100 header-custom">
          <p>Login</p>
        </div>
        <Form.Group className="mb-3 input-field">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.touched.email && !!formik.errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 input-field">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={!!formik.touched.password && !!formik.errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.password}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="login-navigate-ref">
          <p className="prominent-text" onClick={openForgotModal}>Forgot Password?</p>
          <p>Don't have an account? <span className="prominent-text" onClick={openSignupModal}>Create here!</span></p>
        </div>
        <Button className="auth-action-btn" onClick={login} variant="primary">
          {loading ? <Spinner animation="border" size={5} /> : "Login"}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
